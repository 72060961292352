import useSWRImmutable from "swr/immutable";
import wretch from "wretch";
import FormDataAddon from "wretch/addons/formData";
import QueryStringAddon from "wretch/addons/queryString";
import { FetcherOptions, PaginationQuery, Searchable } from "../types";

export namespace EB {
  interface ErrorDetails {
    worksheet: string;
    row: number;
  }

  interface Error {
    code: number;
    message: string;
    details?: ErrorDetails;
  }

  export interface ErrorResponse {
    error: Error;
  }

  export type CompanyId = number;
  export type MemberId = number;

  export interface Company {
    id: CompanyId;
    name: string;
    member_id: MemberId;
    member_name: string;
    permissions: string[];
  }

  export interface CompanyConfigurationStatus {
    is_complete: boolean;
  }

  export interface WorksheetData {
    id: number;
    end_date: string;
    locked_at?: string;
    company_id: number;
  }

  export interface PostWorksheetDataBody {
    member_id: number;
  }

  export interface PostWorksheetLockDataBody {
    status: "locked" | "unlocked";
  }

  export interface CategoryData {
    id: number;
    name: string;
    amount: number;
  }

  export interface ProductData {
    id: number;
    description: string;
    quantity?: number;
    net_price?: number;
  }

  export interface PostProductDataBody {
    description: string;
    quantity?: number;
    net_price?: number;
    category_id: number;
  }

  export interface DeleteProductDataBody {
    ids: number[];
  }

  export interface ProductResponse {
    total: number;
    amount: number;
    products: ProductData[];
  }

  export namespace Admin {
    export type WorksheetStatus = "not_started" | "in_progress" | "completed";

    interface CompanyWorksheet {
      id: number;
      name: string;
      status: WorksheetStatus;
      worksheet?: WorksheetData;
    }

    export interface PatchMemberDataBody {
      end_date: string;
    }

    export interface PostMemberSettingBody extends PatchMemberDataBody {
      member_id: number;
    }

    export interface MemberSetting extends PostMemberSettingBody {
      id: number;
      name: string;
    }

    export interface MemberSettingWorksheet extends MemberSetting {
      status: WorksheetStatus;
      companies: CompanyWorksheet[] | null;
    }

    export interface MemberWorksheetResponse {
      worksheets: MemberSettingWorksheet[];
    }
  }
}

const api = wretch()
  .addon(QueryStringAddon)
  .options({ credentials: "include" })
  .url("/api/v1");

const fetcher = <Response>({ url, query, signal }: FetcherOptions) =>
  api
    .options({ signal })
    .url(url)
    .query(query ?? {})
    .get()
    .json<Response>();

export const parseErrorResponse = (error: Error): EB.ErrorResponse =>
  JSON.parse(error.message);

export const useCompanies = (query: PaginationQuery) =>
  useSWRImmutable({ url: `/companies`, query }, (opts) =>
    fetcher<{ items: EB.Company[] }>(opts),
  );

export const useCompany = (id: EB.CompanyId) =>
  useSWRImmutable(id > 0 ? { url: `/companies/${id}` } : null, (opts) =>
    fetcher<EB.Company>(opts),
  );

export const useCompanyConfigurationStatus = (id: EB.CompanyId) =>
  useSWRImmutable({ url: `/companies/${id}/configuration_status` }, (opts) =>
    fetcher<EB.CompanyConfigurationStatus>(opts),
  );

export const useWorksheetList = (companyId: EB.CompanyId) =>
  useSWRImmutable({ url: `/companies/${companyId}/worksheets` }, (opts) =>
    fetcher<{ worksheets: EB.WorksheetData[] }>(opts),
  );

export const useWorksheetData = (worksheetId: number) =>
  useSWRImmutable(
    worksheetId > 0 ? { url: `/worksheets/${worksheetId}` } : null,
    (opts) => fetcher<EB.WorksheetData>(opts),
  );

export const postWorksheetData = (
  companyId: EB.CompanyId,
  body: EB.PostWorksheetDataBody,
) =>
  api
    .url(`/companies/${companyId}/worksheets`)
    .post(body)
    .json<EB.WorksheetData>();

export const postWorksheetLock = (
  companyId: number,
  worksheetId: number,
  body: EB.PostWorksheetLockDataBody,
) =>
  api
    .url(`/companies/${companyId}/worksheets/${worksheetId}/lock_status`)
    .post(body)
    .res();

export const copyWorksheetData = (worksheetId: number) =>
  api.url(`/worksheets/${worksheetId}:copy`).post().res();

export const getUseCategoryListUrl = (worksheetId: number) =>
  `/worksheets/${worksheetId}/categories`;

export const useCategoryList = (worksheetId: number) =>
  useSWRImmutable({ url: getUseCategoryListUrl(worksheetId) }, (opts) =>
    fetcher<{ categories: EB.CategoryData[] }>(opts),
  );

export const useCategoryData = (
  worksheetId: EB.CompanyId,
  categoryId: number,
) =>
  useSWRImmutable(
    { url: `/worksheets/${worksheetId}/categories/${categoryId}` },
    (opts) => fetcher<EB.CategoryData>(opts),
  );

export const getUseProductListUrl = (worksheetId: number) =>
  `/worksheets/${worksheetId}/products`;

export const useProductList = (
  worksheetId: number,
  query: PaginationQuery<{ category?: number }>,
) =>
  useSWRImmutable({ url: getUseProductListUrl(worksheetId), query }, (opts) =>
    fetcher<EB.ProductResponse>(opts),
  );

export const postProductData = (
  worksheetId: number,
  body: EB.PostProductDataBody,
) =>
  api
    .url(`/worksheets/${worksheetId}/products`)
    .post(body)
    .json<EB.ProductData>();

export const patchProductData = (
  productId: number,
  body: EB.PostProductDataBody,
) => api.url(`/products/${productId}`).patch(body).json<EB.ProductData>();

export const deleteProductsData = (body: EB.DeleteProductDataBody) =>
  api.url(`/products:batchDelete`).post(body).res();

export const useMemberSettingsWorksheetsYears = () =>
  useSWRImmutable({ url: `/administration/member_settings/years` }, (opts) =>
    fetcher<{ years: number[] }>(opts),
  );

export const useAdminMembersWorksheets = (
  query: PaginationQuery & {
    year?: number;
  },
) =>
  useSWRImmutable(
    {
      url: `/administration/worksheets`,
      query,
    },
    (opts) => fetcher<EB.Admin.MemberWorksheetResponse>(opts),
  );

export const patchMemberSetting = (
  memberSettingId: number,
  body: EB.Admin.PatchMemberDataBody,
) =>
  api
    .url(`/administration/member_settings/${memberSettingId}`)
    .patch(body)
    .json<EB.Admin.MemberSetting>();

export const useSearchMemberSettings = (opts: Searchable) =>
  useSWRImmutable(
    { url: `/administration/member_settings`, ...opts },
    (opts) =>
      fetcher<{ members: EB.Admin.MemberSetting[] }>(opts).then(
        (res) => res.members,
      ),
    {
      keepPreviousData: true,
    },
  );

export const postMemberSetting = (body: EB.Admin.PostMemberSettingBody) =>
  api
    .url(`/administration/member_settings`)
    .post(body)
    .json<EB.Admin.MemberSettingWorksheet>();

export const postWorksheetImport = (worksheetId: number, file: File) =>
  api
    .addon(FormDataAddon)
    .url(`/administration/worksheets/${worksheetId}/import`)
    .formData({ file })
    .post()
    .res();
