import { PropsWithChildren } from "react";
import SvgWarningAmber from "../../icons/WarningAmber.svg?react";
import Icon from "./Icon";
import styles from "./Warning.module.css";

const Warning = ({ children }: PropsWithChildren) => (
  <div className={styles.warning}>
    <Icon glyph={SvgWarningAmber} className={styles.icon} />
    {children}
  </div>
);

export default Warning;
