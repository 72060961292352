import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import welcomingSVG from "../assets/welcoming.svg";
import { useAuth } from "../context/authContext";
import { useCompany } from "../context/companyContext";
import NotificationDispatch, {
  showErrorNotification,
} from "../context/notificationContext";
import { WorksheetProvider } from "../context/worksheetContext";
import {
  EB,
  postWorksheetData,
  useCompanyConfigurationStatus,
} from "../http/endbestaendeApi";
import SvgForwardArrow from "../icons/ForwardArrow.svg?react";
import { getRemoteDataStatus } from "../utils";
import CategoryPage from "./CategoryPage";
import CompanySelect from "./CompanySelect";
import DetailPage from "./DetailPage";
import EmptyPage from "./EmptyPage";
import ErrorPage from "./ErrorPage";
import Header from "./Header";
import styles from "./Home.module.css";
import Worksheet from "./Worksheet";
import WorksheetSelect from "./WorksheetSelect";
import Button from "./ui/Button";
import ErrorText from "./ui/ErrorText";
import LoadingSpinner from "./ui/LoadingSpinner";
import Warning from "./ui/Warning";

const Home = () => {
  const auth = useAuth();
  const { company, status } = useCompany();
  const dispatch = useContext(NotificationDispatch);

  if (!auth.session) return null;

  return (
    <>
      <Header>
        <CompanySelect />
      </Header>
      {status === "success" &&
        (!company ? (
          <EmptyPage title="Kein Betrieb gewählt" image={welcomingSVG}>
            Bitte wählen Sie einen Ihrer Betriebe aus.
          </EmptyPage>
        ) : (
          <Routes>
            <Route index element={<Navigate to="worksheets" replace />} />
            <Route path="worksheets">
              <Route
                index
                element={
                  <Worksheet companyId={company.id}>
                    {({ worksheets, mutate }) =>
                      worksheets.length > 0 ? (
                        <div className={styles.date}>
                          <WorksheetSelect worksheets={worksheets} />
                        </div>
                      ) : (
                        <Welcome
                          companyId={company.id}
                          createWorksheet={() => {
                            postWorksheetData(company.id, {
                              member_id: company.member_id,
                            })
                              .then((res) =>
                                mutate({
                                  worksheets: [...worksheets, res],
                                }),
                              )
                              .catch((err) =>
                                dispatch(
                                  showErrorNotification(
                                    err,
                                    "Es ist ein Fehler aufgetreten.",
                                  ),
                                ),
                              );
                          }}
                        />
                      )
                    }
                  </Worksheet>
                }
              />
              <Route path=":worksheetId">
                <Route index element={<Navigate to="categories" replace />} />
                <Route path="categories">
                  <Route
                    index
                    element={
                      <WorksheetProvider>
                        <CategoryPage companyId={company.id} />
                      </WorksheetProvider>
                    }
                  />
                  <Route
                    path=":categoryId"
                    element={
                      <WorksheetProvider>
                        <DetailPage />
                      </WorksheetProvider>
                    }
                  />
                </Route>
              </Route>
            </Route>
          </Routes>
        ))}

      {status === "failure" && (
        <ErrorPage title="Fehler beim Laden der Betriebsdaten"></ErrorPage>
      )}
    </>
  );
};

interface WelcomeProps {
  companyId: EB.CompanyId;
  createWorksheet: () => void;
}

const Welcome = ({ companyId, createWorksheet }: WelcomeProps) => {
  const { data, isValidating, error } =
    useCompanyConfigurationStatus(companyId);
  const canCreateWorksheet = data?.is_complete;
  const status = getRemoteDataStatus({ isValidating, error });

  return (
    <EmptyPage title="Willkommen bei HGV Endbestände" image={welcomingSVG}>
      <p className={styles.intro}>
        Hier haben Sie die Möglichkeit, die jährliche Erfassung der Endbestände
        an Waren und Gütern in Ihrem Betrieb durchzuführen.
      </p>
      {status === "success" &&
        (canCreateWorksheet ? (
          <Button
            buttonProps={{
              onClick: createWorksheet,
            }}
            glyph={SvgForwardArrow}
          >
            Jetzt loslegen
          </Button>
        ) : (
          <Warning>
            Die Konfiguration des Betriebes wurde noch nicht vollständig
            abgeschlossen. Bitte wenden Sie sich an die HGV-Steuerberatung.
          </Warning>
        ))}
      {status === "validating" && <LoadingSpinner />}
      {status === "failure" && (
        <ErrorText text="Fehler beim Laden der Konfigurationsdetails." />
      )}
    </EmptyPage>
  );
};

export default Home;
